import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`After a long winter of hard work in front of the computer, we are finally ready
to publish Trailguide 2020! The app has a new look, improved usability,
new functions, a new biking & outdoor specific topographical map, and
super-useful map overlays. See below what's new:`}</p>
    <br />
    <h4>{`Become a Trailguide Patreon`}</h4>
    <p>{`For us Trailguide is a labor of love and it is free of charge to use.
However, with the increased amount of traffic there are also increasing costs
for the digital services to keep Trailguide running smoothly.
On our Patreon page you have the chance to support
us economically by pledging a small amount per month: `}<a parentName="p" {...{
        "href": "https://www.patreon.com/trailguideapp?fan_landing=true"
      }}>{`Become a Trailguide Patreon`}</a>{`.`}</p>
    <Image src="/news/StartScreenEuropeT.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <h4>{`Find and Share`}</h4>
    <p>{`That is the central purpose
of Trailguide, and it just became a lot better and more enjoyable to explore the
world of trails with `}<a parentName="p" {...{
        "href": "https://trailguide.net"
      }}>{`trailguide.net`}</a>{`!`}</p>
    <Image src="/news/TrailSelectedT.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`Search and Filter`}</h4>
    <p>{`You immediately see that the main user interface is completely new,
being search centered with a new filter and map selector. Start searching at
anytime wherever you are in the app. Also at the top: The filter to the left,
and the login/menu to the right. The functionality of the filter was extended.
Filter for trails that are rated very good or where the conditions are
perfect.`}</p>
    <Image src="/news/Filter3T.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <h4>{`Easy Access to Trails`}</h4>
    <p>{`The button in the middle of the left screen edge opens the Trail list.
See a list of the content visible on the current map section. Filter and
search work here as well. The other tabs show the reviews and condition reports
of the trails in this area.`}</p>
    <Image src="/news/TrailListExpl.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <h4>{`Easy Access to Maps`}</h4>
    <p>{`The map & overlay selector is on the right side. Thumbnails that show a preview
make it easy to choose the right map for your purpose. The main map is the Trailguide
map, developed from scratch for biking and outdoor activities. More on that later!`}</p>
    <Image src="/news/MapSelectorExpl.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <h4>{`Map Overlays`}</h4>
    <p>{`There are several new overlays that help you to find new trails all over
the world, get important information and plan your tour. Adjust the transparency
of every overlay by moving the eye left and right.`}</p>
    <Image src="/news/OverlaySelectorT.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <h4>{`300.000 MTB Tracks`}</h4>
    <p>{`Find almost 300.000 rated MTB tracks all over the world! Based on OpenStreetMap
data this overlay shows singletracks that are ridable with a MTB. Rated with the
Single Trail Scale (S0-S5) for difficulty and color coded (green, blue, red, black)
with the Trailguide rating system. Zoom in to see the MTB specific trail names
and the difficulty rating.`}</p>
    <Image src="/news/MTBtracksOverlay3.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <h4>{`Go & No go areas`}</h4>
    <p>{`The laws to access nature reserves or other protected areas are for sure
different in every country. The overlay helps you to see if the tour you are
planning is in a protected area (brown). Military zones are shown in red.`}</p>
    <Image src="/news/ProtectedareasOverlayT.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <h4>{`Where do others ride?`}</h4>
    <p>{`The Strava Heatmap is very helpful to see where bikers ride. Does a trail have
traffic? Where is the next frequented uphill to access the trail? Even find
trails that are not in the base map! Move the eye to the right to see what way
type is underneath the heatmap.`}</p>
    <Image src="/news/StravaOverlayBoth.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <h4>{`Beautiful new Map`}</h4>
    <p>{`The new map is developed from scratch and optimized for mountain biking and
other outdoor sports. The focus lays on the terrain and trails. Hillshadings
and contour lines create a 3D effect and provide valuable information about
the shape of the landscape. It is very easy to separate all kinds of narrow
trails from wider gravel or paved roads.`}</p>
    <Image src="/news/MapMultiple1.jpg" className="mx-auto my-12" mdxType="Image" />
    <p>{`Other important information to plan a tour are parkings, water sources, huts
and shelters, peaks, viewpoints, toilets, ... and many more. Further zoomed in,
you will see more and more details and names of the objects. Contour lines in
10m steps provide a good picture of the terrain. Stay tuned for another article
with a deeper look into the style and the development process of this map.`}</p>
    <Image src="/news/MapMultiple2.jpg" className="mx-auto my-12" mdxType="Image" />
    <h4>{`Upload trails`}</h4>
    <p>{`Besides that, we made more improvements that you don't see at first sight. With the
new trail editor it became very easy to upload and edit trails. Open `}<a parentName="p" {...{
        "href": "https://trailguide.net"
      }}>{`trailguide.net`}</a>{`
on your desktop computer for this feature.`}</p>
    <br />
We hope you enjoy the new version of Trailguide. Use it to get out on
your bike, have fun and to enjoy nature!

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      